import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import {
  Layout,
  SEO,
  Banner,
  Content,
  HTMLContent
} from '../components/common';
import website from '../../config/website';

const Text = styled.div`
  margin-top: -5em;
`;

export const AboutPageTemplate = ({
  image,
  title,
  content,
  contentComponent
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <Banner image={image} title={title} top />
      <Text>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <PageContent className="content" content={content} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Text>
    </>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func.isRequired
};

const AboutPage = ({
  data: {
    markdownRemark: { frontmatter, html }
  },
  location
}) => (
  <Layout customSEO>
    <SEO
      title={`${frontmatter.title} | ${website.siteTitle}`}
      pathname={location.pathname}
    />
    <AboutPageTemplate
      contentComponent={HTMLContent}
      image={frontmatter.image}
      title={frontmatter.title}
      content={html}
    />
  </Layout>
);

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }).isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
