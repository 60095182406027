import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const PreviewCompatibleImage = ({ imageInfo }) => {
  const imageStyle = { borderRadius: '5px' };
  const { alt = '', childImageSharp, img } = imageInfo;

  if (!!img && !!img.childImageSharp)
    return (
      <Img style={imageStyle} fluid={img.childImageSharp.fluid} alt={alt} />
    );

  if (childImageSharp)
    return <Img style={imageStyle} fluid={childImageSharp.fluid} alt={alt} />;

  if (!!img && typeof img === 'string')
    return <img style={imageStyle} src={img} alt={alt} />;

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object
  }).isRequired
};

export default PreviewCompatibleImage;
