import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

export const Title = styled.div`
  font-family: ${p => p.theme.headerFontFamily};
  background-color: #bd10e0;
  font-size: 3rem;
  color: white;
  line-height: 1.125;
  padding: 1rem 1.5rem;
  position: absolute;
`;

const BanerTitle = ({ children }) => <Title>{children}</Title>;

BanerTitle.propTypes = {
  children: PropTypes.string.isRequired
};

export default BanerTitle;
